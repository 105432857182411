import React, { Component } from 'react';
import _ from 'lodash';
import Plot from 'react-plotly.js';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { getHeatmapData } from   '../features/experiments/experimentsActions';
import { setHeatmapMax, setHeatmapMin } from '../features/results/resultsActions';
import AwesomeDebouncePromise from "awesome-debounce-promise";

class Heatmap extends Component {
    state = {
      loading: true,
      xVals : [],
      yVals : [],
      zVals : [],
      customData: []
    }

    debouncedGetData = AwesomeDebouncePromise(this.props.getHeatmapData, 500);

    componentDidMount() {
        const accession_ids = _.map(this.props.hits, 'accession_id');

        this.props.getHeatmapData(
            this.props.token,
            this.props.pageData.meta.id,
            this.props.minCount,
            this.props.minReplicates,
            {
                accession_ids
            }
        ).then(resp => {
            if (resp) {
                const zVals = _.map(resp['heatmap_data'], obj => obj.slice(0, -resp['heatmap_len'] - 2));
                const customData = _.map(resp['heatmap_data'], obj => obj.slice(resp['heatmap_len'], -2));

                this.props.setHeatmapMax(this.props.heatmapMax);
                this.props.setHeatmapMin(this.props.heatmapMin);

                this.setState({
                    xVals: resp['heatmap_columns'].slice(0, -resp['heatmap_len'] - 2),
                    yVals: _.map(resp['heatmap_data'], obj => `${obj.slice(-2)[0].split(';')[0]} (${obj.slice(-1)[0]})`),
                    zVals,
                    loading: false,
                    customData,
                })
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.volcanoUnique !== this.props.volcanoUnique ||
            prevProps.volcanoType !== this.props.volcanoType ||
            prevProps.minCount !== this.props.minCount ||
            prevProps.minReplicates !== this.props.minReplicates
        ) {
            const accession_ids = _.map(this.props.hits, 'accession_id');

            this.debouncedGetData(
                this.props.token,
                this.props.pageData.meta.id,
                this.props.minCount,
                this.props.minReplicates,
                {
                    accession_ids
                }
            ).then(resp => {
                if (resp) {
                    const zVals = _.map(resp['heatmap_data'], obj => obj.slice(0, -resp['heatmap_len'] - 2));
                    const customData = _.map(resp['heatmap_data'], obj => obj.slice(resp['heatmap_len'], -2));

                    this.setState({
                        xVals: resp['heatmap_columns'].slice(0, -resp['heatmap_len'] - 2),
                        yVals: _.map(resp['heatmap_data'], obj => `${obj.slice(-2)[0].split(';')[0]} (${obj.slice(-1)[0]})`),
                        zVals,
                        loading: false,
                        customData,
                    });
                }
            });
        }
    }

    render() {
        const { xVals, yVals, zVals, loading, customData } = this.state
        const { colorScheme, results } = this.props

        if ( loading ) {
            return <Spin style={{marginTop: 10}} />
        }

        const data = [
            {
              type: 'heatmap',
              autocolorscale: false,
              colorscale: colorScheme,
              x: xVals,
              y: yVals,
              z: zVals,
              hoverongaps: false,
              zauto: false,
              zmax: results.heatmapMax,
              zmin: results.heatmapMin,
              name: '',
              customdata: customData,
              hovertemplate: `<b>Log<sub>2</sub>FC:</b> %{z}<br />
<b>Log<sub>10</sub>P-Value:</b> %{customdata}<br />
<b>Treatment:</b> %{x}<br />
<b>Accession:</b> %{y}<br />
              `
          },
        ]
        
        return <>
              <Plot
                config={{
                  displaylogo: false,
                  responsive: true,
                  toImageButtonOptions: {
                    format: 'svg',
                    filename:'hits_heatmap'
                  }
                }
              }
                data={data}
                layout={{
                  margin: {t:0,r:0,l:20},
                  hovermode: 'closest',
                  autosize: true,
                  showlegend: false,
                  xaxis: {
                    title: 'Treatments',
                  },
                  yaxis: {
                    title: {
                      text: 'Hits',
                      standoff: 10,
                    },
                    automargin: true,

                  }
                }}
                useResizeHandler={true}
                style={{width: "100%"}}
              />
        </>
    }
}

const mapStateToProps = ( { experiments, results }) => {
  return { 
    experiments,
    results
  }
}
export default connect (mapStateToProps, {
  getHeatmapData,
  setHeatmapMax, 
  setHeatmapMin,
}) (Heatmap)